<template>
  <div class="workform">
    <h4 class="form-title">{{ opts.formName }}</h4>

    <el-form :model="forms" ref="forms" :rules="rules" :label-width="110">
      <table class="form-table">
        <tr style="height:0px;">
          <td style="width:18%;"></td>
          <td style="width:32%;"></td>
          <td style="width:18%;"></td>
          <td style="width:32%;"></td>
        </tr>
        <tr>
          <td class="form-label">公司名称：</td>
          <td class="form-label form-input" colspan="2" style="text-align: left;">
            <el-tree-select ref="companys" v-model="forms.companyId" :data="companys" clearable
              @change="forms.departmentIds = null; companyChange()" check-strictly :render-after-expand="false"
              style="width:300px" />
          </td>
          <td class="form-label form-input">
            <el-upload ref="upload" action :show-file-list="false" :multiple="false" :auto-upload="false"
              :on-change="uploadBatch" style="display:inline-flex;vertical-align:middle;">
              <template #trigger>
                <el-button plain type="primary" icon="upload" size="small" style="margin-left: 10px;">批量导入</el-button>
              </template>
            </el-upload>

            <el-button plain icon="download" @click="downloadBatch()" size="small"
              style="margin-left: 10px;">下载模板</el-button>
          </td>
        </tr>
        <tr>
          <td class="form-label">费用部门：</td>
          <td class="form-label form-input" colspan="3" style="text-align: left;">
            <el-tree-select ref="departmentIds" v-model="forms.departmentIds" style="width: 100%"
              @change="departmentChange" :data="departments" multiple collapse-tags-tooltip filterable
              :render-after-expand="false" placeholder="可多选部门" />
          </td>
        </tr>

        <tr>
          <td class="form-input form-center">费用名称：</td>
          <td class="form-input" colspan="3">
            <el-input v-model="forms.billName"></el-input>
          </td>
        </tr>
        <tr>
          <td class="form-input form-center">支出金额：</td>
          <td class="form-input" style="text-align: left;">
            <el-input-number v-model="forms.expenseFee" :min="-10000000" :max="10000000"
              placeholder="0.00"></el-input-number>
          </td>
          <td class="form-input form-center">收入金额：</td>
          <td class="form-input" style="text-align: left;">
            <el-input-number v-model="forms.revenueFee" :min="-10000000" :max="10000000"
              placeholder="0.00"></el-input-number>
          </td>
        </tr>
        <tr>
          <td class="form-label">费用日期：</td>
          <td class="form-label form-input" colspan="3">
            <el-date-picker v-model="forms.billDate" type="date" />
          </td>
        </tr>
        <tr>
          <td class="form-input" colspan="4">
            <table class="child-table" style="margin:10px;table-layout: fixed;">
              <tr style="height:0px;">
                <td style="width:7%;"></td>
                <td style="width:10%;"></td>
                <td style="width:7%;"></td>
                <td style="width:14%;"></td>
                <td style="width:10%;"></td>
                <td style="width:10%;"></td>
                <td style="width:10%;"></td>
                <td style="width:10%;"></td>
                <td style="width:8%;"></td>
                <td></td>
              </tr>
              <tr v-for="(item, index) in forms.details">
                <td class="form-label">部门</td>
                <td class="form-label">
                  <el-input v-model="item.departmentName" style="max-width:130px;" disabled></el-input>
                </td>
                <td class="form-label">类型</td>
                <td class="form-label">
                  <el-select v-model="item.type" placeholder="请选择">
                    <el-option label="直接分摊" value="直接分摊"> </el-option>
                    <el-option label="后勤分摊" value="后勤分摊"> </el-option>
                    <el-option label="行政分摊" value="行政分摊"> </el-option>
                    
                    <el-option label="纯利收入" value="纯利收入"> </el-option>
                  </el-select>
                </td>
                <td class="form-label">费用支出</td>
                <td class="form-label">
                  <el-input-number v-model="item.expenseFee" :min="-10000000" :max="10000000" placeholder="0.00"
                    style="max-width: 80px;"></el-input-number>
                </td>
                <td class="form-label">费用收入</td>
                <td class="form-label">
                  <el-input-number v-model="item.revenueFee" :min="-10000000" :max="10000000" placeholder="0.00"
                    style="max-width: 80px;"></el-input-number>
                </td>
                <td class="form-label">说明</td>
                <td class="form-label form-input">
                  <el-input v-model="item.desc" style="width: 100%" autocomplete="on"></el-input>
                </td>
              </tr>
            </table>
          </td>
        </tr>
      </table>
    </el-form>
  </div>
</template>

<script>
import request from '@/utils/request'
export default {
  name: "equally",
  components: {},
  data() {
    return {
      initOpts: {
        formType: 'FYFT',
        formCode: 'equally',
        formName: '费用录入单据',
        flowCode: 'flow-equally',
        status: 0
      },
      forms: this.formdata,
      rules: {},

      companys: [],
      departments: [],

      rules: {
        // departmentIds: [
        //   //{ message: '请至少选择一个分摊的部门', type: 'array', min: 1, max: 5 }
        //   { required: true, message: '请至少选择一个分摊的部门', trigger: 'change' }
        // ],
        formName: [
          { required: true, message: "请输入分摊的费用名称", trigger: "blur" },
        ],
        billName: [
          { required: true, message: "请输入分摊的费用用途", trigger: "blur" },
        ],
        expenseFee: [
          { required: true, message: "请输入正确的费用金额", trigger: "blur" },
        ],
        billDate: [
          { required: true, message: "请选择分摊的费用日期", trigger: "blur" },
        ],
      },

    };
  },
  props: {
    userinfo: {
      type: Object,
      default: {},
    },
    opts: {
      type: Object,
      default: {}
    },
    formdata: {
      type: Object,
      default: {},
    }
  },

  methods: {
    back() {
      this.$router.go(-1);
    },
    getInitOpts() {
      return this.initOpts;
    },
    formCheck() {
      if (!this.forms.companyId || !this.forms.departmentIds) {
        this.$message({ type: "info", message: "请选择对应的公司和部门", });
        return false;
      }

      this.forms.companyName = this.$refs.companys.getNode(this.forms.companyId).data.companyName;
      //this.forms.departmentName = this.$refs.departments.getNode(this.forms.departmentId).data.departmentName;
      if (this.forms.departmentIds.length == 0) {
        this.$message({ type: "info", message: "请至少选中一个分摊的部门", });
        return false;
      }

      if (!this.forms.billName) {
        this.$message({ type: "info", message: "请输入申请的费用名称", });
        return false;
      }
      if ((this.forms.expenseFee || 0) <= 0 && (this.forms.revenueFee || 0) <= 0) {
        this.$message({ type: "info", message: "请填写收入或支出的金额", });
        return false;
      }
      if (!this.forms.billDate) {
        this.$message({ type: "info", message: "请选择申请的费用日期", });
        return false;
      }

      let valider = true;
      let expenseFee = 0.00; let revenueFee = 0.00;
      this.forms.details.forEach((item, index) => {
        expenseFee = expenseFee + parseFloat(item.expenseFee + '');
        revenueFee = revenueFee + parseFloat(item.revenueFee + '');
        if (!item.type) {
          this.$message('费用类型错误，请选择对应的费用类型');
          valider = false;
        }
      });
      //console.log(expenseFee.toFixed(2), this.forms.expenseFee.toFixed(2));
      if (expenseFee.toFixed(2) != this.forms.expenseFee.toFixed(2)) {
        this.$message('支出金额合计不等于总支出金额，请检查金额');
        return false;
      }
      if (revenueFee.toFixed(2) != this.forms.revenueFee.toFixed(2)) {
        this.$message('收入金额合计不等于总收入金额，请检查金额');
        return false;
      }

      return valider;
    },
    getForms() {
      this.opts.formDesc = '费用分摊/利润:' + this.forms.billName + ';总支出:' + (this.forms.expenseFee || '0');
      if (this.forms.revenueFee > 0) {
        this.opts.formDesc += ';总收入:' + (this.forms.revenueFee || '0')
      }
      return this.forms;
    },

    companyChange() {
      //this.forms.departmentIds = null;
      var that = this;
      if (!this.forms.companyId) {
        return;
      }

      this.$kaung.departments(this.forms.companyId).then((res) => {
        that.departments = res;
      });
    },
    departmentChange() {
      this.forms.details = [];
      this.forms.departmentIds = this.forms.departmentIds || [];
      this.forms.departmentIds.forEach((departmentId) => {
        let departmentName = this.$refs.departmentIds.getNode(departmentId).data.departmentName;
        //let items = this.departments.filter(item => item.id == departmentId);
        this.forms.details.push({
          "departmentId": departmentId, "departmentName": departmentName,
          "type": "", "revenueFee": 0, "expenseFee": 0,
          //'billCode': this.forms.billCode + '-' + (this.forms.billNumber + this.forms.details.length).toString().padStart(3, '0')
          'desc': ''
        })
      });
    },

    uploadBatch(file) {
      var formData = new FormData();
      formData.append(file.name, file.raw);
      formData.append("fileType", 'excel');
      formData.append("dataType", this.$options.name);

      this.$refs.upload.clearFiles();

      request({
        url: 'Common/ResolveTable',
        method: 'post',
        data: formData
      }).then((res) => {
        if (res.code === 200) {
          this.$message({ message: "解析成功", type: "success", });

          this.forms.details = [];
          this.forms.departmentIds = [];
          res.data.forEach((line) => {
            this.departments.forEach((department) => {
              if (line.部门名称 && line.部门名称 == department.departmentName) {
                this.forms.departmentIds.push(department.id);
                this.forms.details.push({
                  "departmentId": department.id, "departmentName": department.departmentName,
                  "type": (line.分摊类型 || ''), "revenueFee": (line.收入金额 || 0), "expenseFee": (line.支出金额 || 0),
                  'desc': (line.分摊描述 || '')
                })
              }
            });
          });
          //this.departmentChange();
        } else
          this.$message.error("上传失败，请重试");
      });
    },
    downloadBatch() {
      window.location.href = process.env.VUE_APP_BASEURL + "static/template/departmentFee.xlsx";
    },
  },
  created() {
    let that = this;
    if (this.opts && this.opts.flowCode)
      this.initOpts = JSON.parse(JSON.stringify(this.opts));

    this.$kaung.companys().then((res) => {
      that.companys = res;
    });

    if (!this.$route.query.id) {
      this.forms.companyId = this.userinfo.companyID;
      this.companyChange();
      //this.forms.departmentId = this.userinfo.departmentID;
    } else {
      this.companyChange();
    }
  },
  watch: {
    formdata: function (newValue, oldValue) {
      console.log(newValue);
    },
  },
  computed: {
    startNode() {
      //草稿或者退回状态
      if (this.initOpts.status == 0 || this.initOpts.status == -10 || this.initOpts.status == '')
        return true;
      return false;
    },
    endNode() {
      if (this.initOpts.status == 50)
        return true;
      return false;
    },
    bossNode() {
      if (this.initOpts.nodeName && (this.initOpts.nodeName.indexOf('总经理') > -1 || this.initOpts.nodeName.indexOf('董事长') > -1))
        return true;
      return false;
    },
    bigFeeStr() {
      return this.$util.toBigMoney(this.forms.expenseFee || 0);
    }
  }

};
</script>

<style scoped>
.el-form-item {
  margin-bottom: 5px;
}

.el-form--inline .el-form-item {
  margin-right: 10px;
}

:deep .el-select {
  vertical-align: initial;
}

:deep .el-input__wrapper,
:deep .el-textarea__inner,
:deep .el-select .el-input.is-focus .el-input__wrapper,
:deep .el-select:hover:not(.el-select--disabled) .el-input__wrapper {
  border: none;
  max-width: 100%;
  box-shadow: none !important;

  padding: 0px 8px;
}

:deep .el-select .el-input__inner {
  padding-top: 1px;
}

:deep .el-form-item__label {
  padding: 0;
}

:deep .el-input-number__increase,
:deep .el-input-number__decrease {
  display: none;
}

/* :deep .el-input__inner {
          text-align: left;
        } */

.workform {
  max-width: 980px;
  margin: 0px auto;
  padding: 20px 20px;
  color: #000;
  background: #fff;
}

.form-table {
  width: 100%;
}

.form-label {
  background: #fff;
  text-align: center;
  padding: 0px;
  font-size: 0.95em;
  font-weight: 500;
  vertical-align: middle;

  border-top: 1px solid #ccc;
  border-left: 1px solid #ccc;
  border-bottom: 1px solid #ccc;
}

.form-input {
  width: auto;
  line-height: 35px;
  font-size: 0.95em;
  border: 1px solid #ccc;
  text-align: left;
  vertical-align: middle;
}


.form-center {
  text-align: center;
}


.child-table {
  font-size: 0.9em;
}

.child-table .form-label,
.child-table .form-input {
  line-height: 30px;
  padding: 5px;
}

.el-tabs__content {
  min-height: 80vh;
}

:deep .el-input-number.is-controls-right .el-input__wrapper {
  padding: 0;
}

:deep .el-input-number .el-input__inner {
  text-align: left;
}
</style>